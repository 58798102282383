@if (!isStation) {
  <div class="grid grid-cols-3 py-0 bg-secondary-400">
    <div
      class="text-white"
      [ngClass]="{
        'col-start-2 text-center': title,
        'h-10 ': !title
      }"
    >
      <h6 class="leading-10">{{ title }}</h6>
    </div>
  </div>
} @else {
  <ng-container *ngTemplateOutlet="newSubHeader"></ng-container>
}

<ng-template #newSubHeader>
  <div
    class="sub-header-new-ui-content"
    [ngClass]="{ 'border-b border-gray-200': isStation }"
  >
    @if (isStation) {
      <section class="sub-header-tab-section">
        <mat-tab-group
          #tabgroup
          mat-stretch-tabs
          mat-align-tabs="center"
          [selectedIndex]="indexTab"
          class="new-station-tabs"
          (selectedIndexChange)="tabSelectedChanged(tabgroup)"
        >
          <mat-tab
            label="{{ termsGeneric.Container.Single }}"
            aria-label="{{ termsGeneric.Container.Single }}"
          >
            <ng-template mat-tab-label>
              <span data-testid="Container-tab" class="font-semibold text-sm">{{
                termsGeneric.Container.Single | uppercase
              }}</span>
            </ng-template>
          </mat-tab>
          @if (isArchitect || isWorkerOrOwner) {
            <mat-tab
              label="Rules"
              aria-label="Rules"
              [disabled]="nonEditableStation"
            >
              <ng-template mat-tab-label>
                <span data-testid="Rules-tab" class="font-semibold text-sm"
                  >RULES</span
                >
              </ng-template>
            </mat-tab>
          }
        </mat-tab-group>
      </section>
    }

    <section
      class="sub-header-station-name-section"
      [ngClass]="{
        'col-start-2 text-center': title,
        'h-10 ': !title
      }"
    >
      @if (isArchitect) {
        <button
          type="button"
          class="inline-block md:hidden"
          data-testid="station-info-button"
          id="station-info-button"
          [disabled]="isDrawerOpen || !itemInfo"
          [matTooltip]="'Open information drawer'"
          matTooltipPosition="left"
          [matMenuTriggerFor]="drawerMenu"
        >
          <i
            class="fa-light fa-info-circle text-lg"
            [ngClass]="
              isDrawerOpen ? 'text-secondary-100' : 'text-secondary-500'
            "
          ></i>
        </button>
      }
      @if (itemInfo) {
        <div class="flex">
          <h6
            class="name-plates"
            [matTooltip]="stationName"
            matTooltipPosition="left"
            appRightClickMenu
            [link]="
              '/' +
              termsGeneric.Station.Single.toLowerCase() +
              '/' +
              stationRithmId
            "
            [enableRightClick]="!isArchitect"
          >
            {{ stationName.toString() | titlecase }}
          </h6>
          <mat-divider [vertical]="true" />
          @if (containerLabel.length) {
            <h6
              class="name-plates"
              [matTooltip]="
                containerLabel.length
                  ? containerLabel
                  : ('Untitled Container' | titlecase)
              "
              matTooltipPosition="right"
            >
              {{
                containerLabel.length
                  ? containerLabel
                  : ('Untitled Container' | titlecase)
              }}
            </h6>
          } @else {
            <ng-container *ngTemplateOutlet="containerLabelLoading" />
          }

          <ng-template #containerLabelLoading>
            <app-loading-indicator
              [inlineText]="'Loading label'"
              class="ml-2"
            />
          </ng-template>
        </div>
      } @else {
        <ng-container *ngTemplateOutlet="namesLoading" />
      }

      <ng-template #namesLoading>
        <app-loading-indicator [inlineText]="' '" />
      </ng-template>
    </section>
    <section class="sub-header-info-buttons-section">
      @if (isArchitect) {
        <button
          class="group order-1 md:order-3"
          type="button"
          [ngClass]="{ active: activeItem === 'stationInfo' }"
          data-testid="station-info-button"
          id="station-info-button"
          mat-icon-button
          [disabled]="isDrawerOpen || !itemInfo"
          [matTooltip]="'Open information drawer'"
          matTooltipPosition="left"
          [matMenuTriggerFor]="drawerMenu"
        >
          <i
            class="fa-light fa-info-circle text-lg"
            [ngClass]="
              isDrawerOpen ? 'text-secondary-100' : 'text-secondary-500'
            "
          ></i>
        </button>
      }
    </section>
  </div>
</ng-template>

<mat-menu
  #drawerMenu="matMenu"
  id="element-drawer"
  hasBackdrop
  class="w-80"
  yPosition="below"
>
  <button mat-menu-item type="button" (click)="toggleDrawer('stationInfo')">
    {{ termsGeneric.Station.Single }} info
  </button>
  <button mat-menu-item type="button" (click)="toggleDrawer('containerInfo')">
    {{ termsGeneric.Container.Single }} info
  </button>
</mat-menu>
